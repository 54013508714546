import React from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton, Icon } from 'antd';
import styled from 'styled-components';
import { colors } from '../../utils';

const ButtonStyle = styled.div`
    .button {
        height: 45px;
        width: 190px;
        width: ${props => (props.size === 'small' ? '170px' : '')};
        width: ${props => (props.size === 'large' ? '200px' : '')};
        height: ${props => (props.size === 'small' ? '35px' : '')};
        height: ${props => (props.size === 'large' ? '55px' : '')};
        color: ${colors.fontLight};
        font-size: 17px;
        transition: all 0.15s ease-in-out;
        &:hover {
            background: ${colors.light};
        }
    }
    .light {
        background: ${colors.light};
        color: ${colors.fontDark};
        border-color: ${colors.dark};
        &:hover {
            background: ${colors.dark};
            color: ${colors.fontLight};
        }
    }
    .dark {
        background: ${colors.dark};
        border-color: ${colors.dark};
        &:hover {
            color: ${colors.fontDark};
        }
    }
    .twitter {
        background: ${colors.twitter};
        border-color: ${colors.twitter};
        &:hover {
            color: ${colors.twitter};
        }
    }
    .youtube {
        background: ${colors.youtube};
        border-color: ${colors.youtube};
        &:hover {
            color: ${colors.youtube};
        }
    }
    .add,
    .check {
        width: 62px;
        height: 62px;
        width: ${props => (props.size === 'small' ? '42px' : '')};
        width: ${props => (props.size === 'large' ? '72px' : '')};
        height: ${props => (props.size === 'small' ? '42px' : '')};
        height: ${props => (props.size === 'large' ? '72px' : '')};
        font-size: 24px;
        color: ${colors.dark};
        border-color: ${colors.dark};
        background: transparent;
        &:hover {
            background: ${colors.dark};
            color: ${colors.light};
        }
    }
    .check {
        background: ${colors.dark};
        color: ${colors.light};
        border-color: ${colors.dark};
        &:hover {
            background: ${colors.light};
            color: ${colors.dark};
        }
    }
`;
const iconButtons = ['add', 'close', 'check'];
const mapTypeToAntType = type => {
    switch (type) {
        case 'add':
            return 'plus';
        case 'close':
            return 'close';
        case 'check':
            return 'check';
        default:
            return '';
    }
};

const Button = ({ type, className, size, ...props }) => (
    <ButtonStyle size={size}>
        {iconButtons.includes(type) ? (
            <AntButton {...props} className={`button  ${type} ${className}`} shape="circle">
                <Icon type={mapTypeToAntType(type)} />
                {props.children}
            </AntButton>
        ) : (
            <AntButton {...props} className={`button ${type} ${className}`} shape="round">
                {props.children}
            </AntButton>
        )}
    </ButtonStyle>
);

Button.defaultProps = {
    type: 'light',
    className: '',
    children: <></>,
    size: 'default'
};

Button.propTypes = {
    type: PropTypes.oneOf(['add', 'close', 'check', 'dark', 'light', 'youtube', 'twitter']),
    className: PropTypes.string,
    children: PropTypes.node,
    size: PropTypes.oneOf(['default', 'small', 'large'])
};

export default Button;
