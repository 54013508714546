import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Input, Button, Alert } from 'antd';
import WithSubmitHandler from '../../components/hocs/WithSubmitHandler';
import './login.css';
import { language } from '../../utils';

const { LocalizeConsumer } = language;

class LoginView extends Component {
    render() {
        const {
            props: {
                form: { getFieldDecorator },
                handleSubmit,
                loading,
                error
            }
        } = this;
        return (
            <LocalizeConsumer>
                {({ strings }) => (
                    <Form onSubmit={handleSubmit} className="login-form">
                        <Form.Item>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        required: true,
                                        message: strings.errors.EMAIL_INPUT
                                    }
                                ]
                            })(
                                <Input
                                    prefix={
                                        <Icon
                                            type="user"
                                            style={{
                                                color: 'rgba(0,0,0,.25)'
                                            }}
                                        />
                                    }
                                    placeholder={strings.common.EMAIL}
                                />
                            )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: strings.errors.PASSWORD_INPUT
                                    }
                                ]
                            })(
                                <Input
                                    prefix={
                                        <Icon
                                            type="lock"
                                            style={{
                                                color: 'rgba(0,0,0,.25)'
                                            }}
                                        />
                                    }
                                    type="password"
                                    placeholder={strings.common.PASSWORD}
                                />
                            )}

                            {error ? (
                                <Alert
                                    message="Invalid user name or password"
                                    type="error"
                                    showIcon
                                />
                            ) : null}
                        </Form.Item>
                        {/*
            <Form.Item>
              {getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: true
              })(
                <Checkbox>
                  <span className="dont-have-text">{strings.login.REMEMBER_ME}</span>
                </Checkbox>
              )} */}
                        {/*
              <a className="login-form-forgot" href="" disabled>
                {`${strings.login.FORGOT_PASSWORD}?`}
              </a> */}
                        {/* </Form.Item> */}

                        <Form.Item>
                            <Button
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                            >
                                <span className="login-button-text">{strings.common.LOGIN}</span>
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </LocalizeConsumer>
        );
    }
}

const formShape = {
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func
};

LoginView.defaultProps = {
    loading: false,
    error: undefined
};

LoginView.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    form: PropTypes.shape(formShape).isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default Form.create({
    mapPropsToFields(props) {
        return {
            email: Form.createFormField(props.formData.email),
            password: Form.createFormField(props.formData.password)
        };
    }
})(WithSubmitHandler({})(LoginView));
