export default {
    INVALID_PASSWORD_FORMAT: 'INVALID_PASSWORD_FORMAT',
    NOT_MATCHING_PASSWORD: 'NOT_MATCHING_PASSWORD',
    USER_EXIST: 'USER_EXIST',
    NOT_CONFIRMED: 'NOT_CONFIRMED',
    WRONG_PASSWORD: 'WRONG_PASSWORD',
    USER_DOES_NOT_EXIST: 'USER_DOES_NOT_EXIST',
    WRONG_CONFIRM_PASSWORD: 'WRONG_CONFIRM_PASSWORD',
    WRONG_OLD_PASSWORD: 'WRONG_OLD_PASSWORD',
    WRONG_TOKEN: 'WRONG_TOKEN',
    NON_EXISTING_EMAIL_TEMPLATE: 'NON_EXISTING_EMAIL_TEMPLATE',
    NO_USER_TOKEN: 'NO_USER_TOKEN',
    USER_TOKEN_EXPIRED: 'USER_TOKEN_EXPIRED',
    WRONG_FORMAT: 'WRONG_FORMAT',
    INVALID_REFRESH_TOKEN: 'INVALID_REFRESH_TOKEN',
    UNAUTHORIZED: 'UNAUTHORIZED',
    CATEGORY_DOES_NOT_EXIST: 'CATEGORY_DOES_NOT_EXIST',
    DID_NOT_DELETE_FROM_AWS: 'DID_NOT_DELETE_FROM_AWS',
    WRONG_FILE_FORMAT: 'WRONG_FILE_FORMAT',
    WRONG_EMAIL_FORMAT: 'WRONG_EMAIL_FORMAT',
    CATEGORY_NOT_IN_INTERESTS: 'CATEGORY_NOT_IN_INTERESTS',
    WRONG_UPLOAD_URL: 'WRONG_UPLOAD_URL',
    USER_NOT_ADMIN: 'USER_NOT_ADMIN',
    USER_WITH_NAME_EXISTS: 'USER_WITH_NAME_EXISTS',
    COUPON_WITH_ID_DOES_NOT_EXIST: 'COUPON_WITH_ID_DOES_NOT_EXIST'
};
