import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Interest from './Interest';
import { colors } from '../../../utils';
import { parseInterestName } from '../utils/notificationUtils';
import Button from '../../../components/buttons/Button';

const SytleChosenInterests = styled.div`
    min-height: 100px;
    width: 600px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: 7px;
    border: 1px solid ${colors.insideBorder};
    background: white;
    margin-bottom: 15px;
    padding: 24px;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        background: ${colors.ribbonDark};
        width: 6px;
        top: 15px;
        bottom: 15px;
    }
`;

const ChosenInterests = props => (
    <SytleChosenInterests>
        {props.interests.map(interest => (
            <Interest
                key={interest.id}
                name={parseInterestName(interest.Name)}
                image={interest.image}
            />
        ))}
        <Button size="small" type="add" onClick={props.handleOpenInterestsModal} />
    </SytleChosenInterests>
);

ChosenInterests.propTypes = {
    interests: PropTypes.array.isRequired,
    handleOpenInterestsModal: PropTypes.func.isRequired
};

export default ChosenInterests;
