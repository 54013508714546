export const parseInterestName = name => {
    const parts = name.split('_');
    return parts
        .map(part => {
            const lowerCasePart = part.toLowerCase();
            return lowerCasePart[0].toUpperCase() + lowerCasePart.slice(1);
        })
        .join(' ');
};
export const interestModalTitles = {
    CATEGORY: "Now Let's select interests for notifications",
    FIRST_SUB_CATEGORY: "Let's get more specific, Please choose a Sub-Category",
    getSubCategoryTitle: name => `These are the Sub-Categories for ${name}`
};
