import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MarketingContainer from './MarketingContainer';
import { MarketingProvider } from './context/MarketingContext';

const PublishRoute = () => (
    <MarketingProvider>
        <Switch>
            <Route exact path="/marketing" component={MarketingContainer} />
        </Switch>
    </MarketingProvider>
);

export default PublishRoute;
