import React, { useState } from 'react';
import { Row, Col, Button, notification } from 'antd';
import moment from 'moment';
import { saveAs } from 'file-saver';

import { api, apollo } from '../../services';
import { codec } from '../../utils';

const FILE_TIMESTAMP_FORMAT = 'YYYY-MM-DD_HH-mm';

const ExportView = () => {
    const [notificationUsersListLoading, setNotificationUsersListLoading] = useState(false);
    const [appUsersListLoading, setAppUsersListLoading] = useState(false);

    const getNotificationUsersList = () => {
        if (notificationUsersListLoading) {
            return;
        }

        setNotificationUsersListLoading(true);
        apollo.client
            .query({
                query: api.GET_NOTIFICATION_USERS_LIST,
                fetchPolicy: 'network-only'
            })
            .then(({ data: { getNotificationUsersList } }) => {
                const fileName = `push-notification-users-${moment().format(
                    FILE_TIMESTAMP_FORMAT
                )}.xlsx`;
                saveAs(codec.base64ToBlob(getNotificationUsersList), fileName, {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
            })
            .catch(error => {
                notification.error({
                    message: error.message
                });
            })
            .finally(() => setNotificationUsersListLoading(false));
    };

    const getAppUsersList = () => {
        if (appUsersListLoading) {
            return;
        }

        setAppUsersListLoading(true);
        apollo.client
            .query({
                query: api.GET_APP_USERS_LIST,
                fetchPolicy: 'network-only'
            })
            .then(({ data: { getAppUsersList } }) => {
                const fileName = `app-users-${moment().format(FILE_TIMESTAMP_FORMAT)}.xlsx`;
                saveAs(codec.base64ToBlob(getAppUsersList), fileName, {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
            })
            .catch(error => {
                notification.error({
                    message: error.message
                });
            })
            .finally(() => setAppUsersListLoading(false));
    };

    return (
        <div className="export-wrapper">
            <Row gutter={24}>
                <Col span={12}>
                    <Button
                        onClick={getNotificationUsersList}
                        loading={notificationUsersListLoading}
                    >
                        Push Notification Users (Excel)
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Button onClick={getAppUsersList} loading={appUsersListLoading}>
                        All App Users (Excel)
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default ExportView;
