import React from 'react';

import styled from 'styled-components';

import { Popover } from 'antd';
import { MenuContext } from '../menu/Context/MenuContext';

import { auth } from '../../services';

import Image from '../image/Image';
import { colors } from '../../utils';

const content = (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}
    >
        <a
            href="/"
            onClick={auth.removeToken}
            style={{
                color: 'rgb(40, 43, 50)'
            }}
        >
            Logout
        </a>
    </div>
);

const StyledHeader = styled.div`
    width: 100%;
    background: ${colors.header};
    height: 60px;
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    flex-shrink: 0;
    &::after {
        content: '';
        height: 2px;
        background: ${colors.headerDivider};
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }
    .link {
        font-size: 22px;
        color: ${colors.fontDark};
        font-family: Helvetica-Bold;
        text-transform: capitalize;
    }
    .img {
        padding-right: 12px;
    }
    .header-right-part {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        padding: 0px 20px;
        font-size: 16px;
    }
    .header-right-part:hover {
        background-color: #f8f9fb !important;
        color: ${colors.dark};
    }
`;

const Header = () => {
    const state = React.useContext(MenuContext);

    return (
        <StyledHeader>
            <div className="link">
                {state.selectedLink ? (
                    <Image className="img" alt="" src={`header/${state.selectedLink}.png`} />
                ) : null}

                {state.selectedLink}
            </div>

            <Popover placement="bottomRight" content={content} trigger="hover">
                <div className="header-right-part">Admin</div>
            </Popover>
        </StyledHeader>
    );
};

export default Header;
