import React from 'react';
import PropTypes from 'prop-types';

const WithSubmitHandler = ({ name = 'onSubmit' }) => Component =>
    class extends React.Component {
        static propTypes = {
            form: PropTypes.shape({
                validateFields: PropTypes.func.isRequired
            }).isRequired
        };

        handleSubmit = e => {
            e.preventDefault();
            const {form: { validateFields }} = this.props;

            validateFields((err, values) => {
                if (!err) {
                    this.props[name](values);
                }
            });
        };

        render() {
            return <Component {...this.props} handleSubmit={this.handleSubmit} />;
        }
    };

export default WithSubmitHandler;
