import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../utils';
import { parseInterestName } from '../utils/notificationUtils';

const StyledCategory = styled.div`
    height: 195px;
    width: 195px;
    background: ${colors.category};
    box-shadow: 2px 8px 11px 0 rgba(24, 24, 25, 0.05);
    border-radius: 3px;
    color: ${colors.fontLight};
    font-size: 17px;
    font-family: Sailec;
    font-weight: bold;
    transition: transform 0.2s ease-in-out;
    display: grid;
    grid-template-rows: 3fr 1fr;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    &:hover {
        transform: scale(1.1);
    }
`;

const Category = ({ category, handleRemoveInterest, handleSelectInterest, ...props }) => (
    <StyledCategory
        onClick={() => props.forward(category)}
    >
        <div>{parseInterestName(category.name)}</div>
    </StyledCategory>
);

Category.propTypes = {
    category: PropTypes.object.isRequired,
    handleSelectInterest: PropTypes.func.isRequired,
    handleRemoveInterest: PropTypes.func.isRequired,
    forward: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired
};

export default Category;
