/* eslint-disable no-console */
import React, { useEffect } from 'react';
import {
    Form,
    Input,
    Checkbox,
    Tabs,
    Select,
    Spin,
    Button,
    TimePicker,
    DatePicker,
    Modal
} from 'antd';
import propTypes from 'prop-types';
import moment from 'moment';
import InterestsModal from './InterestsModal';
import Footer from './Footer';
import Categories from './interests/Categories';
import SubCategories from './interests/SubCategories';
import AddInterests from './interests/AddInterests';
import ChosenInterests from './interests/ChosenInterests';
import TextArea from '../../components/inputs/TextArea';
import './marketing.css';
import { helpers, api, apollo } from '../../services';
import cities from '../../config/cities.json';

const { TabPane } = Tabs;
const CheckboxGroup = Checkbox.Group;
const InputGroup = Input.Group;
const { Option } = Select;

const plainOptions = ['M', 'F'];

const allUserOptions = ['Guests', 'Users'];

const MarketingView = ({ form, stateDerivators, state, getCouponData, getCouponUrl }) => {
    const { getFieldDecorator } = form;

    useEffect(() => {
        apollo.client
            .query({
                query: api.GET_ALL_NOTIFICATIONS,
                variables: { payload: { limit: 10, offset: 0 } },
                fetchPolicy: 'network-only'
            })
            .then(({ data }) =>
                data.getAllNotifications.data.map(notification => {
                    if (
                        moment(notification.params.time).isBetween(
                            moment().subtract(14, 'days'),
                            moment()
                        )
                    ) {
                        let productId = Number(notification.params.productId);
                        const { time, externalURL } = notification.params;

                        if (!productId && externalURL) {
                            try {
                                const parsedUrl = new URL(externalURL);
                                productId = parseInt(
                                    parsedUrl.searchParams.get('ProductId')
                                    || parsedUrl.searchParams.get('redirectProduct'),
                                    10
                                );
                            } catch (e) {
                                console.warn(
                                    `error parsing externalURL [notification=${JSON.stringify(notification)}]`
                                );
                            }
                        }

                        if (Number.isInteger(productId)) {
                            getCouponData(notification.id, productId, time);
                            getCouponUrl(productId, time);
                        } else {
                            console.warn(
                                `missing productId, skipping [notificationId=${notification.id}]`
                            );
                        }
                    }
                })
            );
    }, []);

    useEffect(() => {
        state.onChange({
            title: {
                value: ''
            },
            message: {
                value: ''
            },
            categoryIds: {
                value: ''
            },
            email: {
                value: ''
            },
            excludedEmails: {
                value: ''
            },
            minimumAge: {
                value: 0
            },
            maximumAge: {
                value: 0
            },
            gender: {
                value: []
            },
            birthDay: {
                value: 0
            },
            productId: {
                value: null
            },
            externalURL: {
                value: null
            },
            apsisSegment: {
                value: null
            },
            apsisSegmentSubtract: {
                value: null
            },
            typeOfPush: {
                value: null
            }
        });
        state.setTypeOfPush('SINGLE_TARGET');
    }, []);

    useEffect(() => {
        if (state.typeOfPush === 'APSIS_SEGMENT') {
            state.getApsisSegments();
        }
    }, [state.typeOfPush, state.getApsisSegments]);

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields(err => {
            if (!err) {
                state.sendNotificationToUsers();
            }
        });
    };

    const confirmSendNotification = () => {
        form.validateFields(err => {
            if (!err) {
                Modal.confirm({
                    centered: true,
                    title: 'Confirm',
                    okButtonProps: {
                        form: 'send-notification-form',
                        key: 'submit',
                        htmlType: 'submit'
                    }
                });
            }
        });
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Form id="send-notification-form" onSubmit={handleSubmit}>
                <div className="form-wrapper">
                    <Form.Item
                        style={{
                            width: '100%'
                        }}
                    >
                        <b
                            style={{
                                alignSelf: 'start'
                            }}
                        >
                            Title:
                        </b>
                        {getFieldDecorator('title', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Title is required'
                                }
                            ]
                        })(
                            <Input
                                style={{
                                    width: '100%'
                                }}
                                className="notification-message"
                                placeholder="Notification title"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <b
                            style={{
                                alignSelf: 'start'
                            }}
                        >
                            Message:
                        </b>
                        {getFieldDecorator('message', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Message is required'
                                },
                                {
                                    max: 150
                                }
                            ]
                        })(
                            <TextArea
                                showCount
                                maxCount={150}
                                className="notification-message"
                                placeholder="Notification message"
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        style={{
                            width: '100%'
                        }}
                    >
                        <b
                            style={{
                                display: 'block',
                                alignSelf: 'flex-start'
                            }}
                        >
                            Date:
                        </b>
                        {getFieldDecorator('date')(
                            <DatePicker
                                style={{
                                    alignSelf: 'start'
                                }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        style={{
                            width: '100%'
                        }}
                    >
                        <b
                            style={{
                                display: 'block',
                                alignSelf: 'flex-start'
                            }}
                        >
                            Time:
                        </b>
                        {getFieldDecorator('time')(<TimePicker format="HH:mm" />)}
                    </Form.Item>
                    <b
                        style={{
                            alignSelf: 'start'
                        }}
                    >
                        On notification click...
                    </b>
                    <Tabs
                        style={{
                            width: '100%'
                        }}
                        onChange={activeKey => state.setTypeOfClick(activeKey)}
                    >
                        <TabPane tab="Lead to coupon detail" key="COUPON_DETAIL">
                            <Form.Item
                                style={{
                                    width: '100%'
                                }}
                            >
                                {getFieldDecorator('productId', {
                                    rules: [
                                        {
                                            required: false
                                        }
                                    ]
                                })(
                                    <Input
                                        style={{
                                            width: '100%'
                                        }}
                                        className="notification-message"
                                        placeholder="Product ID"
                                    />
                                )}
                            </Form.Item>
                        </TabPane>
                        <TabPane tab="Lead to external url" key="EXTERNAL_URL">
                            <Form.Item
                                style={{
                                    width: '100%'
                                }}
                            >
                                {getFieldDecorator('externalURL', {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Not a valid url',
                                            type: 'url'
                                        }
                                    ]
                                })(
                                    <Input
                                        style={{
                                            width: '100%'
                                        }}
                                        className="notification-message"
                                        placeholder="External url (http or https prefix required)"
                                    />
                                )}
                            </Form.Item>
                        </TabPane>
                    </Tabs>
                    <Tabs
                        style={{
                            width: '100%'
                        }}
                        onChange={activeKey => state.setTypeOfPush(activeKey)}
                    >
                        <TabPane tab="Single target" key="SINGLE_TARGET">
                            {state.typeOfPush === 'SINGLE_TARGET' ? (
                                <Form.Item
                                    style={{
                                        width: '100%'
                                    }}
                                >
                                    <b
                                        style={{
                                            alignSelf: 'start'
                                        }}
                                    >
                                        Email:
                                    </b>
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                required: state.typeOfPush === 'SINGLE_TARGET',
                                                message: 'Email is required'
                                            },
                                            {
                                                type: 'email',
                                                message: 'Wrong email format'
                                            }
                                        ]
                                    })(
                                        <Input
                                            style={{
                                                width: '100%'
                                            }}
                                            className="notification-message"
                                            placeholder="Email"
                                        />
                                    )}
                                </Form.Item>
                            ) : null}
                        </TabPane>
                        <TabPane tab="Filter users" key="FILTERED_USERS">
                            {state.typeOfPush === 'FILTERED_USERS' ? (
                                <>
                                    <Form.Item
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        {getFieldDecorator('gender', {
                                            rules: [
                                                {
                                                    required: false
                                                }
                                            ]
                                        })(
                                            <CheckboxGroup
                                                options={plainOptions}
                                                value={state.checkedList}
                                                onChange={checked =>
                                                    state.selectCheckBox(checked, plainOptions)
                                                }
                                            />
                                        )}
                                    </Form.Item>
                                    <InputGroup
                                        style={{
                                            display: 'flex'
                                        }}
                                    >
                                        <Form.Item
                                            label="Minimum age: "
                                            style={{
                                                width: '200px'
                                            }}
                                        >
                                            {getFieldDecorator('minimumAge', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        pattern: new RegExp(
                                                            '^(5[5-9]|[6-9][0-9]|10[0-9]|110)$'
                                                        ),
                                                        message: 'In range between 55 and 110'
                                                    }
                                                ]
                                            })(
                                                <Input
                                                    style={{ width: 100 }}
                                                    placeholder="Minimum"
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            label="Maximum age: "
                                            style={{
                                                width: '200px'
                                            }}
                                        >
                                            {getFieldDecorator('maximumAge', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        pattern: new RegExp(
                                                            '^(5[5-9]|[6-9][0-9]|10[0-9]|110)$'
                                                        ),
                                                        message: 'In range between 55 and 110'
                                                    }
                                                ]
                                            })(
                                                <Input
                                                    style={{ width: 100 }}
                                                    placeholder="Maximum"
                                                />
                                            )}
                                        </Form.Item>
                                    </InputGroup>

                                    <Form.Item
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <b
                                            style={{
                                                alignSelf: 'start',
                                                marginRight: '20px'
                                            }}
                                        >
                                            Cities :
                                        </b>
                                        {getFieldDecorator('cities')(
                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Cities"
                                            >
                                                {cities.map(city => (
                                                    <Option key={city}>{city}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <b
                                            style={{
                                                alignSelf: 'start',
                                                marginRight: '20px'
                                            }}
                                        >
                                            Birthday :
                                        </b>
                                        {getFieldDecorator('birthDay', {
                                            rules: [
                                                {
                                                    required: false,
                                                    pattern: new RegExp('^[0-9]{4,6}$'),
                                                    message: 'Wrong format'
                                                }
                                            ]
                                        })(
                                            <Input
                                                style={{ width: '100%' }}
                                                placeholder="YYMMDD or MMDD"
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <b
                                            style={{
                                                alignSelf: 'start',
                                                marginRight: '20px'
                                            }}
                                        >
                                            Exclude :
                                        </b>
                                        {getFieldDecorator('excludedEmails', {
                                            rules: [
                                                {
                                                    required: false,
                                                    validator: (rule, value, callback) => {
                                                        const isValid = /^(\S+@([a-z0-9-]+\.)+[a-z0-9]+(\s+|$))*$/i.test(
                                                            value
                                                        );
                                                        if (!isValid) {
                                                            callback(false);
                                                        }
                                                        callback();
                                                    },
                                                    message: 'Wrong format'
                                                }
                                            ]
                                        })(
                                            <TextArea
                                                className="notification-message"
                                                placeholder="Emails to exclude, one per line"
                                                autoSize={{
                                                    minRows: 2,
                                                    maxRows: 10
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        {!stateDerivators.showSavedInterests({
                                            ...state
                                        }) ? (
                                            <AddInterests
                                                handleOpenInterestsModal={() => {
                                                    state.openInterestsModal(true);
                                                }}
                                            />
                                        ) : (
                                            <>
                                                <ChosenInterests
                                                    handleOpenInterestsModal={() => {
                                                        state.openInterestsModal(true);
                                                    }}
                                                    interests={state.savedInterests}
                                                />
                                            </>
                                        )}
                                        <InterestsModal
                                            interestsModalVisible={state.interestsModalVisible}
                                            closeinterestsModal={() => {
                                                state.openInterestsModal(false);
                                            }}
                                            title={stateDerivators.getInterestModalTitle({
                                                ...state
                                            })}
                                            searchForInterests={state.setInterestsSearchTerm}
                                            searchTerm={state.interestsSearchTerm}
                                            back={state.popInterestFromNavigationStack}
                                            showNavigationButton={
                                                !!state.interestNavigationStack.length
                                            }
                                            footer={
                                                state.interestNavigationStack.length ? (
                                                    <Footer
                                                        saveInterests={state.saveInterests}
                                                        interests={state.chosenInterests}
                                                    />
                                                ) : null
                                            }
                                            handleCloseModal={state.resetInterestsState}
                                        >
                                            {!state.interestNavigationStack.length ? (
                                                <Categories
                                                    loading={state.loadingInterests}
                                                    categories={stateDerivators.getNoParentCategories(
                                                        {
                                                            ...state
                                                        }
                                                    )}
                                                    selectInterest={state.selectInterest}
                                                    forward={state.pushInterestToNavigationStack}
                                                />
                                            ) : (
                                                <SubCategories
                                                    subCategories={stateDerivators.getSubCategories(
                                                        {
                                                            ...state
                                                        }
                                                    )}
                                                    selectInterest={state.selectInterest}
                                                    forward={state.pushInterestToNavigationStack}
                                                    handleRemoveInterest={state.removeInterest}
                                                    showSelectAllButton={() =>
                                                        stateDerivators.showSelectAllButton({
                                                            ...state
                                                        })
                                                    }
                                                    selectAll={
                                                        state.selectAllInterestForSubCategory
                                                    }
                                                />
                                            )}
                                        </InterestsModal>
                                    </Form.Item>
                                </>
                            ) : null}
                        </TabPane>
                        <TabPane tab="Segment" key="APSIS_SEGMENT">
                            {state.typeOfPush === 'APSIS_SEGMENT' ? (
                                <>
                                    <Form.Item label={<b>Add</b>}>
                                        {getFieldDecorator('apsisSegment', {
                                            rules: [
                                                {
                                                    required: false
                                                }
                                            ]
                                        })(
                                            <Select
                                                showSearch
                                                loading={state.loadingApsisSegments}
                                                notFoundContent={
                                                    state.loadingApsisSegments ? <Spin tip="Loading..." /> : 'No Data'
                                                }
                                            >
                                                {state.apsisSegments
                                                    ? state.apsisSegments.map(segment => (
                                                        <Option key={segment.value}>{segment.name}</Option>
                                                    ))
                                                    : null}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label={<b>Subtract</b>}>
                                        {getFieldDecorator('apsisSegmentSubtract', {
                                            rules: [
                                                {
                                                    required: false
                                                }
                                            ]
                                        })(
                                            <Select
                                                showSearch
                                                loading={state.loadingApsisSegments}
                                                notFoundContent={
                                                    state.loadingApsisSegments ? <Spin tip="Loading..." /> : 'No Data'
                                                }
                                            >
                                                {state.apsisSegments
                                                    ? state.apsisSegments.map(segment => (
                                                        <Option key={segment.value}>{segment.name}</Option>
                                                    ))
                                                    : null}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </>
                            ) : null}
                        </TabPane>
                        <TabPane tab="All users" key="ALL_USERS">
                            {state.typeOfPush === 'ALL_USERS' ? (
                                <Form.Item
                                    style={{
                                        width: '100%'
                                    }}
                                >
                                    {getFieldDecorator('typeOfUsers', {
                                        rules: [
                                            {
                                                required: false
                                            }
                                        ]
                                    })(
                                        <CheckboxGroup
                                            options={allUserOptions}
                                            value={state.checkedTypes}
                                            onChange={checked =>
                                                state.selectTypeOfUsers(checked, allUserOptions)
                                            }
                                        />
                                    )}
                                </Form.Item>
                            ) : null}
                        </TabPane>
                    </Tabs>
                    <Form.Item style={{ display: 'flex', width: '100%' }}>
                        {state.typeOfPush === 'FILTERED_USERS' ? (
                            <Button
                                size="large"
                                type="default"
                                icon="right"
                                className="push-button"
                                onClick={confirmSendNotification}
                            >
                                Send
                            </Button>
                        ) : (
                            <Button
                                size="large"
                                type="primary"
                                icon="right"
                                className="push-button"
                                onClick={confirmSendNotification}
                            >
                                Send
                            </Button>
                        )}
                        <Button
                            id="calculate"
                            size="large"
                            type="default"
                            className="push-button"
                            onClick={() => {
                                document.getElementById('calculate').blur();
                                state.sendNotificationToUsers({ getCount: true });
                            }}
                        >
                            {state.loadingCount ? <Spin /> : 'Calculate'}
                        </Button>
                        {state.notificationToUsersCount || state.notificationToUsersCount === 0 ? (
                            <span style={{ marginLeft: 16 }}>
                                <strong>{state.notificationToUsersCount}</strong> out of{' '}
                                <strong>{state.totalUsersCount}</strong> users will receive.
                            </span>
                        ) : null}
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

MarketingView.propTypes = {
    form: propTypes.object.isRequired,
    stateDerivators: propTypes.object.isRequired,
    state: propTypes.object.isRequired
};

export default Form.create({
    name: 'notification',
    onFieldsChange(props, changedFields) {
        props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        return helpers.mapPropsToFields(props.formData);
    }
})(MarketingView);
