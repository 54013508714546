import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../utils/colors';
import Modal from '../../components/modal/Modal';
import Input from '../../components/inputs/Input';
import BackButton from '../../components/buttons/BackButton';

const ModalHeader = styled.div`
    width: 100%;
    font-size: 22px;
    color: ${colors.fontDark};
    font-family: Helvetica;
    font-weight: bold;
    text-align: center;

    .back {
        position: absolute;
        top: 30px;
        left: 20px;
    }
`;

const ModalContent = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    .input-interest-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        margin-top: 30px;
        position: relative;
        z-index: 100;
    }
    .input-interest {
        background: ${colors.interestModal};
        width: 400px;
    }
`;

const modalBodyStyle = {
    minHeight: '615px',
    height: '615px',
    background: `${colors.interestModal}`
};

const InterestsModal = props => (
    <Modal
        visible={props.interestsModalVisible}
        footer={props.footer}
        title={
            <ModalHeader>
                {props.showNavigationButton ? (
                    <BackButton className="back" handleClick={props.back} />
                ) : (
                    ''
                )}
                {props.title}
            </ModalHeader>
        }
        bodyStyle={modalBodyStyle}
        onCancel={props.closeinterestsModal}
        width="70%"
        titleAlignment="center"
        afterClose={() =>
            props.handleCloseModal({
                onlyInterests: true
            })
        }
    >
        <ModalContent>
            <Input
                inputWrapperClassName="input-interest-wrapper"
                className="input-interest"
                placeholder="Type in the interest name"
                iconPrefix="search"
                value={props.searchTerm}
                onChange={evt => {
                    props.searchForInterests(evt.target.value);
                }}
            />

            {props.children}
        </ModalContent>
    </Modal>
);

InterestsModal.defaultProps = {
    footer: null,
    showNavigationButton: false
};

InterestsModal.propTypes = {
    footer: PropTypes.node,
    interestsModalVisible: PropTypes.bool.isRequired,
    closeinterestsModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    searchForInterests: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    back: PropTypes.func.isRequired,
    showNavigationButton: PropTypes.bool,
    searchTerm: PropTypes.string.isRequired
};

export default InterestsModal;
