import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../utils/colors';
import Button from '../../components/buttons/Button';
import { parseInterestName } from './utils/notificationUtils';
import Interest from './interests/Interest';

const StyledFooter = styled.div`
    height: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .interests-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .label {
        font-weight: bold;
        font-family: Sailec;
        font-size: 20px;
        color: ${colors.fontDark};
        text-align: left;
        padding-right: 12px;
        white-space: nowrap;
    }
    .save-btn {
        width: 140px;
    }
`;

const Footer = props => (
    <StyledFooter>
        <div className="label">Chosen Interests:</div>
        <div className="interests-wrapper">
            {props.interests.map(interest => (
                <Interest
                    key={interest.id}
                    name={parseInterestName(interest.Name)}
                    image={interest.image}
                />
            ))}{' '}
        </div>
        <Button className="save-btn" type="dark" onClick={props.saveInterests}>
            Save
        </Button>
    </StyledFooter>
);

Footer.propTypes = {
    interests: PropTypes.array.isRequired,
    savedInterests: PropTypes.func.isRequired
};
export default Footer;
