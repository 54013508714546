import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Image from '../image/Image';
import { colors } from '../../utils';

const StyledBackButton = styled.div`
    cursor: pointer;
    font-size: 17px;
    font-family: Helvetica;

    .link {
        display: flex;
        align-items: center;
    }

    .label {
        color: ${colors.fontDark};
        padding-left: 12px;
    }
`;

const BackButton = props => (
    <StyledBackButton className={props.className}>
        {!props.destination ? (
            <div onClick={props.handleClick}>
                <Image src="buttons/back-btn.png" alt="No Icon" />
            </div>
        ) : (
            <Link to={props.destination} className="link">
                <Image src="buttons/back-btn.png" alt="No Icon" />
                <div className="label">{`Back ${props.previous}`}</div>
            </Link>
        )}
    </StyledBackButton>
);

BackButton.defaultProps = {
    className: '',
    previous: '',
    handleClick: undefined,
    destination: undefined
};

BackButton.propTypes = {
    previous: PropTypes.string,
    className: PropTypes.string,
    destination: PropTypes.string,
    handleClick: PropTypes.func
};

export default BackButton;
