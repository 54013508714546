import { Form } from 'antd';

export default (formData = {}) => {
    let mapped = {};
    Object.keys(formData).map(field => {
        if (formData[field]) {
            mapped = {
                ...mapped,
                [field]: Form.createFormField(formData[field])
            };
        }
        return null;
    });
    return mapped;
};
