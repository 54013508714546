// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

const USER = `
MemberId
UserGuid
FirstName
LastName
Email
UserName
Gender
Address
City
Zip
PensionersAssociationId
CivicRegistrationNumber
Age
Phone
CellPhone
InterestAreas
AllowedToBeContacted
AllowedToPromote
ValidTo
MemberStatusId
CardNumber
PensionerAssociationMemberNumber
ARegionName
ARegionNumber
CoAddress
ExtraSmartEndSubscription
PhoneProvider
MemberAreasOfInterest
MemberUnsubscribedMailingLists
`;

const USER_TOKEN = `
UserToken {
  id
  userId
  accessToken
  refreshToken
  expiryDate
}`;

export default {
    LOGIN_MUTATION: gql`
    mutation loginAdmin($payload: loginWithEmail!) {
      loginAdmin(payload: $payload) {
        ${USER}
        ${USER_TOKEN}
      }
    }`,
    LOGOUT_MUTATION: gql`
        mutation {
            logout
        }
    `,
    FORGOT_PASSWORD_MUTATION: gql`
        mutation forgotPassword($payload: email!) {
            forgotPassword(payload: $payload)
        }
    `,

    CONFIRM_FORGOT_PASSWORD_MUTATION: gql`
        mutation confirmForgotPassword($payload: confirmForgotPassword!) {
            confirmForgotPassword(payload: $payload)
        }
    `
};
