import gql from '../../../node_modules/graphql-tag';

export default {
    GET_CATEGORIES: gql`
        query getCategories($payload: getCategories) {
            getCategories(payload: $payload) {
                id
                name
                categories {
                    CategoryId
                    Name
                    ImageMediaId
                    DisplayOnSite
                    ParentCategoryId
                    ProductCatalogId
                    SortOrder
                    DefinitionId
                }
            }
        }
    `
};
