import LocalizedStrings from 'react-localization';

export const errorsStrings = new LocalizedStrings({
    en: {
        COUPON_WITH_ID_DOES_NOT_EXIST: 'Coupon with id does not exist',
        INVALID_PASSWORD_FORMAT:
            'A minimum 8 characters password must contains a combination of uppercase, lowercase letter and number.',
        NOT_MATCHING_PASSWORD: 'Two passwords that you enter is inconsistent!',
        USER_EXIST: 'Email is already taken!',
        NOT_CONFIRMED: 'NOT_CONFIRMED',
        WRONG_PASSWORD: 'WRONG_PASSWORD',
        USER_DOES_NOT_EXIST: 'USER_DOES_NOT_EXIST',
        WRONG_CONFIRM_PASSWORD: 'Wrong confirm password',
        WRONG_OLD_PASSWORD: 'WRONG_OLD_PASSWORD',
        WRONG_TOKEN: 'WRONG_TOKEN',
        NON_EXISTING_EMAIL_TEMPLATE: 'NON_EXISTING_EMAIL_TEMPLATE',
        NO_USER_TOKEN: 'NO_USER_TOKEN',
        USER_TOKEN_EXPIRED: 'USER_TOKEN_EXPIRED',
        WRONG_FORMAT: 'WRONG_FORMAT',
        INVALID_REFRESH_TOKEN: 'INVALID_REFRESH_TOKEN',
        UNAUTHORIZED: 'UNAUTHORIZED',
        CATEGORY_DOES_NOT_EXIST: 'CATEGORY_DOES_NOT_EXIST',
        DID_NOT_DELETE_FROM_AWS: 'DID_NOT_DELETE_FROM_AWS',
        WRONG_FILE_FORMAT: 'WRONG_FILE_FORMAT',
        CATEGORY_NOT_IN_PREFERENCES: 'CATEGORY_NOT_IN_PREFERENCES',
        WRONG_UPLOAD_URL: 'WRONG_UPLOAD_URL',
        USER_NOT_ADMIN: 'USER_NOT_ADMIN',
        USER_WITH_NAME_EXISTS: 'User already exist with that name',
        EMAIL_INPUT: 'Please input your email!',
        PASSWORD_INPUT: 'Please input your password!',
        CONFIRM_PASSWORD_INPUT: 'Please confirm your password!',
        FULL_NAME_INPUT: 'Please input your name!',
        WRONG_EMAIL_FORMAT: 'The input is not valid E-mail!'
    }
});
