/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

const parseLocationPathname = pathname => {
    const parts = pathname.split('/').filter(el => el);
    return parts[0] || 'home';
};

export const MenuContext = React.createContext({
    selectedLink: 'home',
    updateLink: () => {},
    updateLinkFromLocation: () => {}
});

export const MenuConsumer = MenuContext.Consumer;

class MenuProvider extends React.Component {
    state = {
        selectedLink: 'home',
        updateLink: link => {
            this.setState({
                selectedLink: link
            });
        },
        updateLinkFromLocation: location => {
            this.setState({
                selectedLink: parseLocationPathname(location)
            });
        }
    };

    render() {
        return (
            <MenuContext.Provider value={this.state}>{this.props.children}</MenuContext.Provider>
        );
    }
}

MenuProvider.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }).isRequired
};

export default MenuProvider;
