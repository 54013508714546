/* eslint-disable radix */
/* eslint-disable object-curly-spacing */
/* eslint-disable react/no-unused-state */
import React from 'react';
import axios from 'axios';
import { notification } from 'antd';
import moment from 'moment';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { api, helpers } from '../../../services';
import { getSubCategories } from './MarketingStateDerivators';
import { sendNotificationErrors } from '../../../utils/errors/formErrors';

const defaultState = {
    notificationTitle: '',
    notificationMessage: '',
    interestsModalVisible: false,
    interestsSearchTerm: '',
    interestNavigationStack: [],
    savedInterests: [],
    chosenInterests: [],
    currentInterest: {},
    interests: [],
    loading: false,
    couponWithViews: {},
    urlViews: [],
    loadingInterests: false,
    formData: {
        minimumAge: { value: '' },
        maximumAge: { value: '' },
        title: { value: '' },
        message: { value: '' },
        email: { value: '' },
        excludedEmails: { value: '' },
        gender: { value: ['M', 'F'] },
        cities: { value: [] },
        birthDay: { value: '' },
        productId: { value: null },
        externalURL: { value: null },
        typeOfUsers: { value: null },
        time: { value: null },
        date: { value: null }
    },
    typeOfPush: null,
    typeOfPushClick: null,
    loadingCount: false,
    notificationToUsersCount: 0,
    totalUsersCount: 0
};

const actionsDefaults = {
    setNotificationTitle: () => {},
    setNotificationMessage: () => {},
    sendNotificationToUsers: () => {},
    openInterestsModal: () => {},
    setInterestsSearchTerm: () => {},
    popInterestFromNavigationStack: () => {},
    resetState: () => {},
    getAllInterests: () => {},
    selectInterest: () => {},
    removeInterest: () => {},
    pushInterestToNavigationStack: () => {},
    selectAllInterestForSubCategory: () => {},
    resetInterestsState: () => {},
    setTypeOfPush: () => {},
    setTypeOfPushClick: () => {},
    selectTypeOfUsers: () => {},
    deleteNotification: () => {},
    getCouponData: () => {},
    getCouponUrl: () => {}
};

export const MarketingContext = React.createContext({
    ...defaultState,
    ...actionsDefaults
});

export const MarketingConsumer = MarketingContext.Consumer;

class MarketingProviderClass extends React.Component {
    state = {
        notificationTitle: '',
        notificationMessage: '',
        interestsModalVisible: false,
        interests: [],
        loading: false,
        loadingInterests: false,
        chosenInterests: [],
        currentInterest: {},
        interestNavigationStack: [],
        savedInterests: [],
        interestsSearchTerm: '',
        apsisSegments: [],
        loadingApsisSegments: false,
        formData: {
            minimumAge: { value: 0 },
            maximumAge: { value: 0 },
            title: { value: '' },
            message: { value: '' },
            email: { value: '' },
            excludedEmails: { value: '' },
            gender: { value: ['M', 'F'] },
            cities: { value: [] },
            birthDay: { value: '' },
            productId: { value: null },
            externalURL: { value: null },
            apsisSegment: { value: null },
            apsisSegmentSubtract: { value: null },
            typeOfUsers: { value: null }
        },
        typeOfPush: null,
        typeOfPushClick: null,
        loadingCount: false,
        getCount: 0,
        totalUsersCount: 0,
        setTypeOfPush: typeOfPush => {
            this.setState(prevState => ({
                typeOfPush,
                formData: {
                    ...defaultState.formData,
                    title: {
                        value: prevState.formData.title.value
                    },
                    message: {
                        value: prevState.formData.message.value
                    }
                }
            }));
        },
        setTypeOfClick: typeOfPushClick => {
            this.setState(prevState => ({
                typeOfPushClick,
                formData: {
                    ...prevState.formData,
                    ...(typeOfPushClick === 'COUPON_DETAIL'
                        ? {
                              externalURL: {
                                  value: null
                              }
                          }
                        : {
                              productId: {
                                  value: null
                              }
                          }),
                    title: {
                        value: prevState.formData.title.value
                    },
                    message: {
                        value: prevState.formData.message.value
                    }
                }
            }));
        },
        onChange: changedFields => {
            this.setState(({ formData }) => {
                if (
                    formData &&
                    formData.minimumAge &&
                    formData.maximumAge &&
                    formData.minimumAge.value &&
                    formData.maximumAge.value &&
                    formData.minimumAge.value > formData.maximumAge.value
                ) {
                    if (changedFields.minimumAge)
                        return {
                            formData: {
                                ...formData,
                                ...changedFields,
                                minimumAge: {
                                    ...formData.minimumAge,
                                    ...(changedFields.minimumAge ? changedFields.minimumAge : {}),
                                    errors: [
                                        {
                                            message: 'Minimum age has to be smaller then maximum!'
                                        }
                                    ]
                                }
                            }
                        };
                }
                if (formData.minimumAge && formData.minimumAge.errors) {
                    // eslint-disable-next-line no-param-reassign
                    formData.minimumAge.errors = undefined;
                }

                return {
                    notificationToUsersCount: null,
                    formData: {
                        ...formData,
                        ...changedFields
                    }
                };
            });
        },
        sendNotificationToUsers: async ({ getCount = false } = {}) => {
            try {
                const {
                    state: { formData, savedInterests, resetInterestsState },
                    props: {
                        client: { mutate }
                    }
                } = this;
                if (getCount) {
                    this.setState({
                        loadingCount: true
                    });
                }

                const getTime = (date, time) => {
                    if (!date) {
                        return null;
                    }
                    const hour = time ? time.hours() : null;
                    const minute = time ? time.minutes() : null;
                    return date
                        .set({
                            hour,
                            minute,
                            second: 0,
                            millisecond: 0
                        })
                        .valueOf();
                };

                console.debug(formData);

                const response = await mutate({
                    mutation: getCount
                        ? api.CALCULATE_NOTIFICATION_TO_USERS
                        : api.SEND_NOTIFICATION_TO_USERS,
                    variables: {
                        payload: {
                            title: formData.title.value,
                            message: formData.message.value,
                            categoryIds:
                                savedInterests && savedInterests.length
                                    ? savedInterests.map(interest => interest.CategoryId)
                                    : [],
                            email: formData.email.value,
                            excludedEmails:
                                this.state.typeOfPush === 'FILTERED_USERS'
                                    ? formData.excludedEmails.value
                                    : null,
                            age: [
                                `${parseInt(formData.minimumAge.value) || 18}-${parseInt(
                                    formData.maximumAge.value
                                ) || 110}`
                            ],
                            gender:
                                this.state.typeOfPush === 'FILTERED_USERS'
                                    ? formData.gender.value || ['M', 'F']
                                    : null,
                            time: getTime(formData.date.value, formData.time.value),
                            regions: formData.cities.value || [],
                            birthDay: formData.birthDay.value || '',
                            productId: formData.productId.value || null,
                            externalURL: formData.externalURL.value || null,
                            apsisSegment: formData.apsisSegment
                                ? formData.apsisSegment.value
                                : null,
                            apsisSegmentSubtract: formData.apsisSegmentSubtract
                                ? formData.apsisSegmentSubtract.value
                                : null,
                            typeOfPush: this.state.typeOfPush || 'SINGLE_TARGET',
                            typeOfPushClick: this.state.typeOfPushClick || null,
                            typeOfUsers:
                                // eslint-disable-next-line no-nested-ternary
                                this.state.typeOfPush === 'ALL_USERS'
                                    ? formData.typeOfUsers.value.length
                                        ? formData.typeOfUsers.value.map(type => type.toUpperCase())
                                        : ['GUESTS', 'USERS']
                                    : null
                        }
                    },
                    refetchQueries: [
                        {
                            query: api.GET_ALL_NOTIFICATIONS,
                            variables: {
                                payload: {
                                    limit: 10,
                                    offset: 0
                                }
                            },
                            fetchPolicy: 'network-only'
                        }
                    ]
                });
                if (getCount) {
                    this.setState({
                        loadingCount: false,
                        notificationToUsersCount:
                            response.data.calculateNotificationToUsers.recievingUsersCount,
                        totalUsersCount: response.data.calculateNotificationToUsers.totalUsersCount
                    });
                }
                if (response.data && response.data.sendNotificationToUsers) {
                    notification.success({
                        message: 'Notification sent'
                    });
                }
                if (!getCount) {
                    resetInterestsState();
                }
                return null;
            } catch (error) {
                const errors = helpers.parseErrors(error);
                this.setState(prevState => ({
                    loadingCount: false,
                    formData: helpers.onFormErrors(
                        prevState.formData,
                        sendNotificationErrors,
                        errors
                    )
                }));
                return null;
            }
        },
        pushInterestToNavigationStack: interest => {
            if (!interest.parent && interest.lastLevel) {
                this.setState({
                    savedInterests: [].concat(interest),
                    interestsModalVisible: false
                });
                return;
            }
            this.setState(({ interestNavigationStack }) => ({
                interestNavigationStack: interestNavigationStack.concat(interest),
                interestsSearchTerm: ''
            }));
        },
        popInterestFromNavigationStack: () => {
            this.setState(({ interestNavigationStack }) => ({
                interestNavigationStack: interestNavigationStack.slice(0, -1),
                interestsSearchTerm: ''
            }));
        },
        selectAllInterestForSubCategory: () => {
            this.setState(
                ({ chosenInterests, interests, interestsSearchTerm, interestNavigationStack }) => {
                    let newInterests = [];
                    const subCategories = getSubCategories({
                        interestsSearchTerm,
                        interestNavigationStack,
                        interests,
                        chosenInterests
                    });
                    subCategories.forEach(subCategory => {
                        if (
                            !chosenInterests.find(
                                element => element.CategoryId === subCategory.CategoryId
                            )
                        ) {
                            newInterests = newInterests.concat(subCategory);
                        }
                    });

                    newInterests.reverse();
                    return {
                        chosenInterests: [...chosenInterests, ...newInterests]
                    };
                }
            );
        },
        saveInterests: () => {
            this.setState(({ chosenInterests }) => ({
                savedInterests: [...chosenInterests],
                interestsModalVisible: false
            }));
        },
        openInterestsModal: value => {
            this.setState({
                interestsModalVisible: value
            });
            if (value) {
                this.state.getAllInterests();
            }
        },
        getCouponUrl: (productId, time) => {
            this.setState({
                loading: true
            });
            if (
                !this.state.urlViews ||
                (this.state.urlViews &&
                    this.state.urlViews.length &&
                    !this.state.urlViews.map(item => item.productId).includes(productId))
            ) {
                const id = parseInt(productId);
                const { query } = this.props.client;
                query({
                    query: api.GET_COUPON_DETAILS,
                    variables: {
                        payload: { productId: id }
                    },
                    fetchPolicy: 'network-only'
                }).then(({ data }) => {
                    const urlParameter = `https://smartsenior.se${data.getCouponDetails.ExternalUrl}`;
                    const username = '5cf7af0f728b94a46e9ed842af657a13';
                    const password = '2e8131176c34fa8a0a0514f171c91346';
                    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
                    const startDate = moment(time).format('YYYYMMDD');
                    const endDate = moment(time)
                        .add(14, 'days')
                        .format('YYYYMMDD');
                    axios
                        .get(
                            `https://amplitude-proxy.nowmatters.com/https://amplitude.com/api/2/events/segmentation?e=\{"event_type":"Viewed%20WebView%20Screen","filters":\[\{"subprop_type":"event","subprop_key":"url","subprop_op":"is","subprop_value":\["${urlParameter}"\]\}\]\}&m=uniques&start=${startDate}&end=${endDate}&i=1&g=platform`,
                            {
                                headers: {
                                    Authorization: `Basic ${token}`
                                }
                            }
                        )
                        .then(res => {
                            this.setState(prevState =>
                                prevState && prevState.urlViews && prevState.urlViews.length
                                    ? {
                                          loading: false,
                                          urlViews: [
                                              ...prevState.urlViews,
                                              {
                                                  productId,
                                                  url: urlParameter,
                                                  views: res.data.data.series
                                                      .flat()
                                                      .reduce(function(a, b) {
                                                          return a + b;
                                                      }, 0)
                                              }
                                          ]
                                      }
                                    : {
                                          loading: false,
                                          urlViews: [
                                              {
                                                  productId,
                                                  url: urlParameter,
                                                  views: res.data.data.series
                                                      .flat()
                                                      .reduce(function(a, b) {
                                                          return a + b;
                                                      }, 0)
                                              }
                                          ]
                                      }
                            );
                        })
                        .catch(error => {
                            console.error(error);
                        })
                        .finally(() => {
                            this.setState(prevState => ({
                                ...prevState,
                                loading: false
                            }));
                        });
                });
            }
            return null;
        },
        getCouponData: (notificationId, productId, time) => {
            this.setState({
                loading: true
            });
            if (!this.state.couponWithViews || !this.state.couponWithViews[notificationId]) {
                const username = '5cf7af0f728b94a46e9ed842af657a13';
                const password = '2e8131176c34fa8a0a0514f171c91346';
                const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
                const startDate = moment(time).format('YYYYMMDD');
                const endDate = moment(time)
                    .add(14, 'days')
                    .format('YYYYMMDD');
                axios
                    .get(
                        `https://amplitude-proxy.nowmatters.com/https://amplitude.com/api/2/events/segmentation?e=\{"event_type":"Viewed%20CouponPreview%20Screen","filters":\[\{"subprop_type":"event","subprop_key":"productId","subprop_op":"is","subprop_value":\["${productId}"\]\}\]\}&m=uniques&start=${startDate}&end=${endDate}&i=1&g=platform`,
                        {
                            headers: {
                                Authorization: `Basic ${token}`
                            }
                        }
                    )
                    .then(res => {
                        const views = res.data.data.series.flat().reduce((a, b) => a + b, 0)
                        this.setState(prevState => ({
                            loading: false,
                            couponWithViews: {
                                ...prevState.couponWithViews,
                                [notificationId]: views
                            }
                        }));
                    })
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.setState({
                            loading: false,
                        });
                    });
            }
            return null;
        },
        getAllInterests: () => {
            this.setState({
                loadingInterests: true
            });
            const { query } = this.props.client;
            query({
                query: api.GET_CATEGORIES
            })
                .then(({ data }) => {
                    const interests = data.getCategories.map(interest => ({
                        ...interest,
                        image: `publish/interests/${interest.name.toLowerCase()}.png`
                    }));

                    this.setState({
                        loadingInterests: false,
                        interests
                    });
                })
                .catch(() => {
                    this.setState({
                        loadingInterests: false
                    });
                });
        },
        selectInterest: interest => {
            this.setState(({ chosenInterests, interests }) => {
                let newInterests = [];
                const addInterest = element => {
                    newInterests = newInterests.concat(element);
                    if (!element.parent) {
                        return;
                    }
                    addInterest(interests.find(category => category.name === element.parent));
                };

                addInterest(interest);
                newInterests.reverse();
                return {
                    chosenInterests: [...chosenInterests, ...newInterests],
                    currentInterest: interest
                };
            });
        },
        removeInterest: id => {
            this.setState(({ chosenInterests }) => ({
                chosenInterests: chosenInterests.filter(el => el.CategoryId !== id)
            }));
        },
        setInterestsSearchTerm: interestsSearchTerm => {
            this.setState({
                interestsSearchTerm
            });
        },
        resetInterestsState: options => {
            this.setState(prevState => ({
                chosenInterests: [],
                interestNavigationStack: [],
                currentInterest: {},
                formData:
                    options && options.onlyInterests ? prevState.formData : defaultState.formData
            }));
        },
        selectCheckBox: checkedList => {
            this.setState({
                checkedList
            });
        },
        selectTypeOfUsers: checkedTypes => {
            this.setState({
                checkedTypes
            });
        },
        deleteNotification: async notificationId => {
            try {
                const {
                    props: {
                        client: { query, mutate }
                    }
                } = this;

                const resp = await mutate({
                    mutation: api.DELETE_NOTIFICATION,
                    variables: {
                        payload: {
                            id: notificationId
                        }
                    }
                });
                if (resp.data) {
                    await query({
                        query: api.GET_ALL_NOTIFICATIONS,
                        variables: {
                            payload: {
                                limit: 10,
                                offset: 0
                            }
                        },
                        fetchPolicy: 'network-only'
                    });
                }
                return null;
            } catch (error) {
                return null;
            }
        },
        getApsisSegments: async () => {
            try {
                this.setState({
                    loadingApsisSegments: true
                });
                const response = await this.props.client.query({
                    query: api.GET_APSIS_SEGMENTS
                });

                const segments = response.data.segments || [];
                const collator = new Intl.Collator('sv', { sensitivity: 'base' });
                segments.sort(
                    (a, b) => collator.compare(a.name, b.name) || collator.compare(a.value, b.value)
                );

                this.setState({
                    apsisSegments: segments
                });
            } catch (error) {
                notification.error({
                    title: 'Error loading segments',
                    message: error.message
                });
            } finally {
                this.setState({
                    loadingApsisSegments: false
                });
            }
        }
    };

    render() {
        return (
            <MarketingContext.Provider value={this.state}>
                {this.props.children}
            </MarketingContext.Provider>
        );
    }
}

MarketingProviderClass.propTypes = {
    children: PropTypes.node.isRequired,
    client: PropTypes.object.isRequired
};

export const MarketingProvider = withApollo(MarketingProviderClass);
