import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { withApollo } from 'react-apollo';
import { Spin } from 'antd';
// eslint-disable-next-line import/no-cycle

const PaginationApollo = ({ children, variables, query, fetchPolicy }) => {
    const { data, error, loading, fetchMore } = useQuery(query, {
        variables,
        fetchPolicy: fetchPolicy || 'cache-first'
    });
    const handleScroll = () => {
        const html = document.documentElement;
        const queryName = data ? Object.keys(data)[0] : null;
        if (
            Math.ceil(html.scrollTop + html.clientHeight) >= html.scrollHeight &&
            data &&
            data[queryName] &&
            data[queryName].pageInfo.hasNextPage &&
            !loading
        ) {
            if (data && data[queryName] && data[queryName].data) {
                fetchMore({
                    variables: {
                        payload: {
                            ...variables.payload,
                            offset: data[queryName].data.length,
                            limit: 3
                        }
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        return Object.assign({}, prev, {
                            [queryName]: {
                                ...prev[queryName],
                                data: [...prev[queryName].data, ...fetchMoreResult[queryName].data],
                                pageInfo: fetchMoreResult[queryName].pageInfo
                            }
                        });
                    }
                });
                document.removeEventListener('scroll', handleScroll, true);
            }
        }
    };
    useLayoutEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    });

    if (loading) {
        return (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: '100px'
                }}
            >
                <Spin />
            </div>
        );
    }
    if (error) {
        return null;
    }

    if (data && data[Object.keys(data)[0]]) {
        return children(data[Object.keys(data)[0]].data);
    }
    return null;
};

PaginationApollo.defaultProps = {
    variables: {},
    fetchPolicy: 'cache-first'
};

PaginationApollo.propTypes = {
    children: PropTypes.func.isRequired,
    query: PropTypes.object.isRequired,
    variables: PropTypes.object,
    fetchPolicy: PropTypes.string
};

export default withApollo(PaginationApollo);
