import React from 'react';
import { Mutation } from 'react-apollo';

const getMutationRequest = (mutationRequest, modifyRequest) => (...args) =>
    mutationRequest(...args).then(modifyRequest);

export default (mutationQuery, modifyRequest) => props => (
    <Mutation mutation={mutationQuery} {...props}>
        {(mutationRequest, response) =>
            props.children(getMutationRequest(mutationRequest, modifyRequest), response)
        }
    </Mutation>
);
