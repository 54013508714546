import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FlexContainerStyled = styled.div`
    height: ${props => props.height};
    width: ${props => props.width};
    display: flex;
    align-items: ${props => props.alignItems};
    justify-content: ${props => props.justifyContent};
`;

class FlexContainer extends React.PureComponent {
    static defaultProps = {
        height: '100vh',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    };

    render() {
        return (
            <FlexContainerStyled
                height={this.props.height}
                width={this.props.width}
                alignItems={this.props.alignItems}
                justifyContent={this.props.justifyContent}
            >
                {this.props.children}
            </FlexContainerStyled>
        );
    }
}

FlexContainer.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    alignItems: PropTypes.string,
    justifyContent: PropTypes.string,
    children: PropTypes.node.isRequired
};
export default FlexContainer;
