// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

const COUPON_DETAILS = `
LongDescription
Details
Company
DiscountInstructions
Code
ExternalUrl
ProductId
ParentProductId
Sku
VariantSku
Name
ProductDefinitionId
Rating
MainImageUrl
DisplayName
ShortDescription
ProductCategoryId
HasCouponCodes
CompanyInfo {
  CompanyName
  CompanyImage
  PartnerId
  Udi
}
Locations {
    Latitude
    Longitude
    Address
    City
    RadiusInMeters
}
`;

export default {
    SEND_NOTIFICATION_TO_USERS: gql`
        mutation sendNotificationToUsers($payload: sendNotification!) {
            sendNotificationToUsers(payload: $payload)
        }
    `,
    GET_ALL_NOTIFICATIONS: gql`
        query getAllNotifications($payload: pagination) {
            getAllNotifications(payload: $payload) {
                data {
                    id
                    userId
                    status
                    params
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    `,
    CHANGED_STATUS: gql`
        subscription changedStatus {
            changedStatus {
                id
                params
                status
            }
        }
    `,
    CALCULATE_NOTIFICATION_TO_USERS: gql`
        mutation calculateNotificationToUsers($payload: sendNotification!) {
            calculateNotificationToUsers(payload: $payload) {
                recievingUsersCount
                totalUsersCount
            }
        }
    `,
    DELETE_NOTIFICATION: gql`
        mutation deleteNotification($payload: notificationId!) {
            deleteNotification(payload: $payload)
        }
    `,
    GET_COUPON_DETAILS: gql`
        query getCouponDetails($payload: productId!) {
            getCouponDetails(payload: $payload) {
            ${COUPON_DETAILS}
            }
        }
    `,
    GET_NOTIFICATION_USERS_LIST: gql`
        query getNotificationUsersList {
            getNotificationUsersList
        }
    `,
    GET_APSIS_SEGMENTS: gql`
        query getApsisSegments {
            segments: getApsisSegments {
                name
                value
            }
        }
    `
};
