import language from './languages.json';
import { LocalizeConsumer, LocalizeProvider } from './Localize';
import * as languageLocalized from './languageLocalized';

export default {
    language,
    LocalizeConsumer,
    LocalizeProvider,
    languageLocalized
};
