import React, { Component } from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';

import { apollo } from '../services';
import Login from './login/LoginContainer';
import Main from './Main';
import PrivateRoute from '../components/private-route/PrivateRoute';

import { language } from '../utils';

const { LocalizeProvider } = language;

export default class App extends Component {
    render() {
        return (
            <LocalizeProvider>
                <ApolloProvider client={apollo.client}>
                    <ApolloHooksProvider client={apollo.client}>
                        <BrowserRouter>
                            <Switch>
                                <Route exact path="/login" render={props => <Login {...props} />} />
                                <PrivateRoute component={Main} />
                            </Switch>
                        </BrowserRouter>
                    </ApolloHooksProvider>
                </ApolloProvider>
            </LocalizeProvider>
        );
    }
}
