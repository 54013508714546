import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../../utils/colors';

const StyledInterest = styled.div`
    background: ${colors.light};
    border: 1px solid ${colors.insideBorder};
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 16px;
    font-family: SFProText-Medium;
    color: ${colors.subCategoryItemName};
    font-size: 16px;
    margin-right: 8px;
    margin-bottom: 4px;
    .image {
        margin-right: 8px;
        height: 16px;
    }
`;

const Interest = ({ name }) => (
    <StyledInterest>
        <div className="image" />
        <div>{name}</div>
    </StyledInterest>
);

Interest.propTypes = {
    name: PropTypes.string.isRequired
};

export default Interest;
