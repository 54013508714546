import { interestModalTitles } from '../utils/notificationUtils';

export const getNoParentCategories = ({ interestsSearchTerm, interests }) => {
    const searchTerm = interestsSearchTerm.toLocaleLowerCase();
    return interests.filter(
        category => !category.parentCategoryId && category.name.toLowerCase().includes(searchTerm)
    );
};

export const getSubCategories = ({
    interestsSearchTerm,
    interestNavigationStack,
    chosenInterests
}) => {
    const searchTerm = interestsSearchTerm.toLocaleLowerCase();
    const navigationStak = interestNavigationStack;
    const subCategories = navigationStak[navigationStak.length - 1].categories.filter(category =>
        category.Name.toLowerCase().includes(searchTerm));
    return subCategories.map(subCategory => ({
        ...subCategory,
        selected: chosenInterests.find(element => element.CategoryId === subCategory.CategoryId)
    }));
};
export const getInterestModalTitle = state => {
    const stackLength = state.interestNavigationStack ? state.interestNavigationStack.length : 0;
    if (!stackLength) {
        return interestModalTitles.CATEGORY;
    }

    return stackLength === 1
        ? interestModalTitles.FIRST_SUB_CATEGORY
        : interestModalTitles.getSubCategoryTitle(
              state.interestNavigationStack[stackLength - 1].name
          );
};

export const showSelectAllButton = ({
    allInterests,
    interestsSearchTerm,
    interestNavigationStack,
    interests,
    chosenInterests
}) => {
    const subCategories = getSubCategories({
        interestsSearchTerm,
        interestNavigationStack,
        interests,
        chosenInterests,
        allInterests
    });
    return !!subCategories.length && subCategories;
};

// eslint-disable-next-line max-len
export const getChosenInterestsIds = ({ chosenInterests }) =>
    chosenInterests.filter(interest => interest.lastLevel).map(interest => interest.id);

export const showChosenInterests = ({ chosenInterests }) => !!chosenInterests.length;

export const showSavedInterests = ({ savedInterests }) => !!savedInterests.length;
