import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { colors } from '../../../utils';
import { parseInterestName } from '../utils/notificationUtils';
import Button from '../../../components/buttons/Button';

const StyledCategories = styled.div`
    height: 85%;
    width: 100%;
    display: grid;
    justify-content: stretch;
    align-content: start;
    padding: 32px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    top: -40px;
    padding-top: 72px;

    .select-all-interest {
        width: 150px !important;
        position: absolute;
        right: 32px;
        top: 0px;
    }
`;
const SubCategories = ({
    showSelectAllButton,
    subCategories,
    selectInterest,
    forward,
    selectAll,
    handleRemoveInterest
}) => (
    <StyledCategories>
        {showSelectAllButton() ? (
            <Button type="light" size="small" onClick={selectAll} className="select-all-interest">
                Select All
            </Button>
        ) : (
            ''
        )}
        {subCategories.map(subCategory => (
            <SubCategory
                key={subCategory.id}
                handleSelectInterest={selectInterest}
                subCategory={subCategory}
                forward={forward}
                handleRemoveInterest={handleRemoveInterest}
            />
        ))}
    </StyledCategories>
);

export default SubCategories;

const StyledSubCategory = styled.div`
    height: 90px;
    width: 100%;
    background: ${colors.subCategory};
    box-shadow: 3px 3px 14px 0px rgba(45, 46, 48, 0.05);
    border-radius: 4px;

    .sub-category-row {
        height: 100%;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        padding: 16px;
    }
    .image {
        text-align: center;
        height: 64px;
        width: 64px;
    }
    .name {
        font-size: 15px;
        font-family: SFProText-Medium;
        color: ${colors.subCategoryItemName};
    }
    .btn {
        text-align: end;
    }
`;

const SubCategory = ({ subCategory, ...props }) => (
    <StyledSubCategory>
        <Row className="sub-category-row">
            <Col span={6}>
                <div className="name">{parseInterestName(subCategory.Name)}</div>
            </Col>
            <Col className="btn" span={2}>
                {// eslint-disable-next-line no-nested-ternary
                !subCategory.selected ? (
                    <Button
                        type="add"
                        size="small"
                        onClick={() => props.handleSelectInterest(subCategory)}
                    />
                ) : (
                    <Button
                        type="check"
                        size="small"
                        onClick={() => props.handleRemoveInterest(subCategory.CategoryId)}
                    />
                )}
            </Col>
        </Row>
    </StyledSubCategory>
);

const subCategoryShape = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};

SubCategory.propTypes = {
    subCategory: PropTypes.shape(subCategoryShape).isRequired,
    forward: PropTypes.func.isRequired,
    handleRemoveInterest: PropTypes.func.isRequired,
    handleSelectInterest: PropTypes.func.isRequired
};
