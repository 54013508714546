import axios from 'axios';
import moment from 'moment';

import { ApolloLink, split } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

import { auth } from '..';
import config from '../../config';
import introspectionQueryResultData from './fragmentTypes.json';

const GRAPHQL_ENDPOINT = config.GRAPHQL_ENDPOINT;
const WS_SERVER = config.WS_SERVER;

const wsLink = new WebSocketLink({
    uri: WS_SERVER,
    options: {
        reconnect: true
    }
});
const httpLink = createHttpLink({
    uri: GRAPHQL_ENDPOINT
});

const link = split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

const authLink = setContext(async (_, { headers }) => {
    try {
        let accessToken = auth.getToken();
        const refreshToken = auth.getRefreshToken();
        // eslint-disable-next-line radix
        const expiryDate = parseInt(auth.getExpiryDate());
        if (
            accessToken &&
            refreshToken &&
            expiryDate &&
            new Date(expiryDate) < moment().add(500, 'ms')
        ) {
            const {
                data: { data }
            } = await axios({
                url: GRAPHQL_ENDPOINT,
                method: 'post',
                mode: 'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                },
                data: {
                    query: `
                    mutation refreshAccessToken($payload: refreshToken!) {
                        refreshAccessToken(payload: $payload) {
                        id
                        accessToken
                        refreshToken
                        expiryDate
                        }
                    }
            `,
                    variables: {
                        payload: {
                            refreshToken
                        }
                    }
                }
            });
            await auth.setToken(data.refreshAccessToken.accessToken);
            await auth.setExpiryDate(data.refreshAccessToken.expiryDate);
            await auth.setRefreshToken(data.refreshAccessToken.refreshToken);
        }
        accessToken = await auth.getToken();

        return {
            headers: {
                ...headers,
                Accept: 'application/json',
                Authorization: accessToken ? `Bearer ${accessToken}` : ''
            }
        };
    } catch (error) {
        return null;
    }
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

const cache = new InMemoryCache({
    fragmentMatcher
});

const defaultState = {
    findUserById: {}
};

const client = new ApolloClient({
    link: ApolloLink.from([authLink, link]),
    cache,
    clientState: {
        defaults: defaultState
    }
});

export default client;
