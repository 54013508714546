/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import React from 'react';
import { Row, Col, Spin, Icon, Button, Popconfirm } from 'antd';
import moment from 'moment';
import PaginationApollo from '../../services/apollo/PaginationApollo';
import { api } from '../../services';

const displayStatus = status => {
    switch (status) {
        case 'SCHEDULED':
            return (
                <img
                    style={{
                        display: 'block',
                        width: '35px'
                    }}
                    src={`${process.env.PUBLIC_URL}/assets/images/common/schedule.png`}
                    alt="scheduled"
                />
            );
        case 'PENDING':
            return (
                <Spin
                    style={{
                        marginLeft: '-20px'
                    }}
                    tip="Loading..."
                />
            );
        case 'COMPLETED':
            return (
                <Icon
                    style={{ fontSize: '25px' }}
                    type="check-circle"
                    theme="twoTone"
                    twoToneColor="#52c41a"
                />
            );
        case 'FAILED':
            return <Icon style={{ fontSize: '25px', color: 'red' }} type="exclamation" />;
        default:
            return (
                <Icon
                    style={{ fontSize: '25px' }}
                    type="exclamation"
                    theme="twoTone"
                    twoToneColor="#52c41a"
                />
            );
    }
};

const NotificationList = ({
    deleteNotification = () => {},
    urlViews,
    couponWithViews,
    loading
}) => {
    // useEffect(() => {
    //     getCouponData('6300');
    // }, []);

    return !loading ? (
        <PaginationApollo
            fetchPolicy="network-only"
            query={api.GET_ALL_NOTIFICATIONS}
            variables={{ payload: { limit: 10, offset: 0 } }}
            subscribeOptions={{
                document: api.CHANGED_STATUS,
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;
                    const changedStatus = subscriptionData.data.changedStatus;

                    return {
                        getAllNotifications: {
                            data: prev.getAllNotifications.data.map(notification => {
                                if (notification.id === changedStatus.id) {
                                    return {
                                        ...notification,
                                        status: changedStatus.status
                                    };
                                }
                                return notification;
                            }),
                            pageInfo: prev.getAllNotifications.pageInfo,
                            __typename: 'NotificationConnection'
                        }
                    };
                }
            }}
        >
            {getNotifications => (
                <div>
                    {getNotifications.map(notification => {
                        return (
                        <Row key={notification.id} className="notification-row">
                            <Col span={12}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>{notification.params.title}</span>
                                    <div>
                                        {couponWithViews
                                        && couponWithViews[notification.id]
                                        && moment(notification.params.time).isBetween(
                                            moment().subtract(14, 'days'),
                                            moment()
                                        ) ? (
                                            <>
                                                <span
                                                    style={{
                                                        backgroundColor: 'rgb(205,226,241)',
                                                        fontWeight: '500',
                                                        color: 'rgb(84,137,180)'
                                                    }}
                                                >
                                                    {' '}
                                                    <div
                                                        style={{
                                                            color: 'red',
                                                            backgroundColor: 'white',
                                                            display: 'inline-block',
                                                            width: '40px',
                                                            textAlign: 'center',
                                                            border: '1px solid #0000ff1f'
                                                        }}
                                                    >
                                                    {couponWithViews[notification.id] || 0}
                                                    </div>
                                                    Users opened the campaign{' '}
                                                </span>
                                                {/* {urlViews && urlViews.length ? (
                                                    <span
                                                        style={{
                                                            backgroundColor: 'rgb(205,226,241)',
                                                            fontWeight: '500',
                                                            color: 'rgb(84,137,180)'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                color: 'red',
                                                                backgroundColor: 'white',
                                                                display: 'inline-block',
                                                                width: '40px',
                                                                textAlign: 'center',
                                                                border: '1px solid #0000ff1f'
                                                            }}
                                                        >
                                                            {urlViews &&
                                                            urlViews.length &&
                                                            urlViews.filter(item => {
                                                                return (
                                                                    item.productId ===
                                                                    notification.params
                                                                        .productId
                                                                );
                                                            })[0]
                                                                ? urlViews.filter(
                                                                        item =>
                                                                            item.productId ===
                                                                            notification.params
                                                                                .productId
                                                                    )[0].views
                                                                : null}
                                                        </div>
                                                        {'  '}Users opened Call for Action{' '}
                                                    </span>
                                                ) : null}{' '} */}
                                            </>
                                        ) : null}
                                    </div>

                                    {notification.params.productId && (
                                        <div style={{ display: 'flex', marginTop: 5 }}>
                                            <strong
                                                style={{
                                                    flex: 0,
                                                    marginRight: '1rem',
                                                    minWidth: '5rem'
                                                }}
                                            >
                                                Product ID
                                            </strong>
                                            <span>{notification.params.productId}</span>
                                        </div>
                                    )}
                                    {notification.params.externalURL && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <strong
                                                style={{
                                                    flex: 0,
                                                    marginRight: '1rem',
                                                    minWidth: '6rem'
                                                }}
                                            >
                                                External URL
                                            </strong>
                                            <div
                                                style={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                <Button
                                                    type="link"
                                                    href={notification.params.externalURL}
                                                    target="_blank"
                                                >
                                                    {notification.params.externalURL}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col span={5}>
                                <span>{notification.params.message}</span>
                            </Col>
                            <Col span={4}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: 5 }}>
                                        {displayStatus(notification.status)}
                                    </span>
                                    {notification.params.time ? (
                                        <span>
                                            {moment(notification.params.time).format('lll')}
                                        </span>
                                    ) : null}
                                </div>
                            </Col>
                            <Col span={3}>
                                <Popconfirm
                                    title="Are you sure you want to delete this notification?"
                                    onConfirm={() => deleteNotification(notification.id)}
                                    onCancel={() => {}}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="danger">Delete</Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    )})}
                </div>
            )}
        </PaginationApollo>
    ) : (
        <Spin style={{ marginTop: '10%', marginLeft: '40%' }} tip="Loading analytics" />
    );
};

export default NotificationList;
