import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input as Icon, Input } from 'antd';
import styled from 'styled-components';
import { colors } from '../../utils';

const { TextArea } = Input;

const StyledTextArea = styled.div`
    position: relative;

    .input {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid ${colors.inputLine};
        width: 596px;
        font-family: Helvetica;
        color: ${colors.fontDark};
        font-size: 25px;
        opacity: 0.5;
        padding-bottom: 10px;
        min-height: 40px;
        &:focus {
            box-shadow: none;
        }
        ${props => (props.prefix ? 'padding-left: 30px;' : '')}
    }
    .small {
        font-size: 20px;
    }
    .medium {
        font-size: 14px;
    }
    .large {
        font-size: 25px;
    }

    .prefix-icon {
        position: relative;
        left: 25px;
        top: 5px;
    }

    .count {
        position: absolute;
        top: -40px;
        right: 0;
        font-size: 1em;

        .error {
            color: ${colors.error};
            transition: all 300ms ease-in;
            sup {
                font-weight: bold;
            }
        }
    }
`;

const FullTextArea = ({
    className,
    inputWrapperClassName,
    size,
    iconPrefix,
    showCount,
    maxCount,
    ...props
}) => {
    const [value, setValue] = useState(props.value);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (props.value !== value) {
            setValue(props.value);
            setCount(props.value && props.value.length || 0);
        }
    }, [value, props.value]);

    const onChange = event => {
        const { value } = event.target;
        setValue(value);
        setCount(value ? value.length : 0);
        return props.onChange && props.onChange(event);
    };

    return (
        <StyledTextArea className={inputWrapperClassName} prefix={iconPrefix}>
            {iconPrefix ? <Icon className={`prefix-icon ${size}`} type={iconPrefix} /> : ''}
            <TextArea
                {...props}
                autoSize={{
                    minRows: 4,
                    maxRows: 8
                }}
                value={value}
                onChange={onChange}
                className={`input ${className} ${size}`}
            />
            {showCount && count > 0 && (
                <div className="count">
                    <span className={count > maxCount ? 'error' : ''}>
                        <sup>{count}</sup>/<sub>{maxCount || '\u221e'}</sub>
                    </span>
                </div>
            )}
        </StyledTextArea>
    );
};

FullTextArea.defaultProps = {
    className: '',
    inputWrapperClassName: '',
    size: 'medium',
    iconPrefix: '',
    showCount: false,
    maxCount: null
};

FullTextArea.propTypes = {
    className: PropTypes.string,
    inputWrapperClassName: PropTypes.string,
    size: PropTypes.string,
    iconPrefix: PropTypes.string,
    showCount: PropTypes.bool,
    maxCount: PropTypes.number
};

export default FullTextArea;
