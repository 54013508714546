import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Category from './Category';
import Spin from '../../../components/spinners/Spin';

const StyledCategories = styled.div`
    height: 86%;
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    padding: 32px;
    overflow-y: auto;
    overflow-x: hidden;

    .categories-holder {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(195px, 195px));
        grid-gap: 32px;
        width: 100%;
        justify-content: center;
    }
`;

const Categories = props => (
    <StyledCategories>
        <Spin spinning={props.loading} size="large">
            <div className="categories-holder">
                {props.categories.map(category => (
                    <Category
                        key={category.id}
                        handleSelectInterest={props.selectInterest}
                        category={category}
                        forward={props.forward}
                    />
                ))}
            </div>
        </Spin>
    </StyledCategories>
);

Categories.defaultProps = {
    loading: false
};

Categories.propTypes = {
    categories: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    selectInterest: PropTypes.func.isRequired
};

export default Categories;
