import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntInput, Icon } from 'antd';
import styled from 'styled-components';
import { colors } from '../../utils';

const StyledInput = styled.div`
    .input {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid ${colors.inputLine};
        width: 596px;
        font-family: Helvetica;
        font-weight: bold;
        color: ${colors.fontDark};
        font-size: 25px;
        opacity: 0.5;
        padding-bottom: 10px;
        height: 40px;
        &:focus {
            box-shadow: none;
        }
        ${props => (props.prefix ? 'padding-left: 30px;' : '')}
    }
    .small {
        font-size: 20px;
    }
    .medium {
        font-size: 22px;
    }
    .large {
        font-size: 25px;
    }

    .prefix-icon {
        position: relative;
        left: 25px;
        top: 5px;
    }
`;

const Input = ({ className, inputWrapperClassName, size, iconPrefix, ...props }) => (
    <StyledInput className={inputWrapperClassName} prefix={iconPrefix}>
        {iconPrefix ? <Icon className={`prefix-icon ${size}`} type={iconPrefix} /> : ''}
        <AntInput {...props} className={`input ${className} ${size}`} />
    </StyledInput>
);

Input.defaultProps = {
    className: '',
    inputWrapperClassName: '',
    size: 'medium',
    iconPrefix: ''
};

Input.propTypes = {
    className: PropTypes.string,
    inputWrapperClassName: PropTypes.string,
    size: PropTypes.string,
    iconPrefix: PropTypes.string
};

export default Input;
