import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import Marketing from './marketing/MarketingRoute';
import Menu from '../components/menu/MenuContainer';

import AppHeader from '../components/header/Header';
import MenuProvider from '../components/menu/Context/MenuContext';
import { colors } from '../utils';
// TODO: move inline styles to separate object
const { Sider, Content } = Layout;

const styles = {
    layout: {
        height: '100%',
        minHeight: '100vh'
    },
    content: {
        background: `${colors.appContent}`,
        overflowY: 'auto',
        padding: '32px',
        position: 'relative',
        height: '100%'
    }
};

export default class Main extends Component {
    render() {
        return (
            <MenuProvider {...this.props}>
                <Layout
                    style={{
                        ...styles.layout
                    }}
                >
                    <Sider
                        width={190}
                        style={{
                            position: 'fixed',
                            height: '100%'
                        }}
                    >
                        <Menu {...this.props} />
                    </Sider>
                    <Layout
                        style={{
                            width: '1250px',
                            marginLeft: '190px'
                        }}
                    >
                        <AppHeader />
                        <Content
                            style={{
                                ...styles.content
                            }}
                        >
                            <Switch>
                                <Route path="/marketing" component={Marketing} />
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </MenuProvider>
        );
    }
}
