/* eslint-disable import/no-dynamic-require */
import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Image from '../image/Image';
import { MenuContext } from './Context/MenuContext';
import { colors } from '../../utils';

const appLogoSrc = 'app-logo/app-logo.png';

const menuItems = [
    {
        label: 'Marketing',
        link: '/marketing',
        key: 'marketing',
        img: ''
    }
];

const StyledMenu = styled.div`
    width: 190px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${colors.menu};
    .menu {
        width: 100%;
        background: ${colors.menu};
        height: 100%;
        border: none;
    }
    .ant-menu-item-selected {
        background: ${colors.selectedMenuItem} !important;
        &::before {
            content: '';
            height: 100%;
            width: 5px;
            background: ${colors.menuItemRibbon};
            border-radius: 0px 5px 5px 0px;
            position: absolute;
            left: 2px;
        }
    }
    .menu-item {
        height: 50px;
        display: flex;
        align-items: center;
        &:hover {
            background: ${colors.hilitedMenuItem} !important;
        }
    }
    .menu-icon {
        margin-right: 10px;
        height: 15px;
        width: 15px;
    }
    .menu-link {
        font-size: 15px;
        color: ${colors.fontLight};
        font-family: SFProDisplay-Regular;
        &:hover {
            color: ${colors.fontLight};
        }
    }
    .logo {
        margin-top: 20px;
    }
    .divider {
        height: 1px;
        background: ${colors.menuDivider};
        margin-top: 12px;
        width: 80%;
    }
`;


const MenuContainer = props => {
    const state = React.useContext(MenuContext);
    const handleMenuClick = menu => {
        state.updateLink(menu.key);
    };

    React.useEffect(() => {
        state.updateLinkFromLocation(props.location.pathname);
    }, []);
    return (
        <StyledMenu>
            <Image className="logo" src={appLogoSrc} alt="app-logo" />

            <div className="divider" />

            <Menu
                className="menu"
                mode="vertical"
                // defaultSelectedKeys={[state.selectedLink]}
                onClick={handleMenuClick}
                selectedKeys={[state.selectedLink]}
            >
                {menuItems.map(item => (
                    <Menu.Item key={item.key} className="menu-item">
                        <Image alt="" src={item.img} className="menu-icon" />

                        <Link className="menu-link" to={item.link}>
                            {item.label}
                        </Link>
                    </Menu.Item>
                ))}
            </Menu>
        </StyledMenu>
    );
};

MenuContainer.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }).isRequired
};

export default MenuContainer;
