import { notification } from 'antd';
import { language, errors } from '../../utils';

export default (error, { title = '', displayError = true } = {}) => {
    if (error && error.graphQLErrors && error.graphQLErrors.length) {
        const parsedErrors = error.graphQLErrors.map(errorItem => errors[errorItem.message]);
        if (displayError !== false) {
            notification.error({
                message: title || 'Error',
                description:
                    parsedErrors && parsedErrors.length
                        ? language.languageLocalized.errorsStrings[parsedErrors[0]]
                        : 'Something went wrong'
            });
        }
        return parsedErrors;
    }
    return [];
};
