import React from 'react';
import { Tabs } from 'antd';
import MarketingView from './MarketingView';
import NotificationList from './NotificationList';
import ExportView from './ExportView';
import { MarketingContext } from './context/MarketingContext';
import * as stateDerivators from './context/MarketingStateDerivators';

const { TabPane } = Tabs;

const MarketingContainer = () => {
    const state = React.useContext(MarketingContext);

    return (
        <Tabs>
            <TabPane tab="Send notification" key="SEND_NOTIFICATION">
                <MarketingView
                    onChange={state.onChange}
                    formData={state.formData}
                    sendNotificationToUsers={state.sendNotificationToUsers}
                    openInterestsModal={state.openInterestsModal}
                    stateDerivators={stateDerivators}
                    state={state}
                    getCouponUrl={state.getCouponUrl}
                    getCouponData={state.getCouponData}
                />
            </TabPane>
            <TabPane tab="All notifications" key="ALL_NOTIFICATIONS">
                <NotificationList
                    getCouponUrl={state.getCouponUrl}
                    urlViews={state.urlViews}
                    getWebViewData={state.getWebViewData}
                    getCouponData={state.getCouponData}
                    couponWithViews={state.couponWithViews}
                    deleteNotification={state.deleteNotification}
                    loading={state.loading}
                />
            </TabPane>
            <TabPane tab="Export lists" key="EXPORT_LISTS">
                <ExportView />
            </TabPane>
        </Tabs>
    );
};

export default MarketingContainer;
