import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../utils';
import Button from '../../../components/buttons/Button';

const StyledAddInterests = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: 110px;
    background: white;
    border-radius: 7px;
    border: 2px dashed ${colors.insideBorder};
    margin-bottom: 15px;
    .add-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        .add-btn-label {
            margin-top: 20px;
            color: ${colors.fontDark};
        }
    }
`;

const AddInterests = props => (
    <StyledAddInterests>
        <div className="add-btn">
            <Button size="small" type="add" onClick={props.handleOpenInterestsModal} />

            <div className="add-btn-label">Add interests</div>
        </div>
    </StyledAddInterests>
);

AddInterests.propTypes = {
    handleOpenInterestsModal: PropTypes.func.isRequired
};

export default AddInterests;
