const colors = {
    youtube: 'rgb(255,37,0)',
    youtubeLight: 'rgb(255,236,233)',
    twitter: 'rgb(60,174,243)',
    twitterLight: 'rgb(223,241,251)',
    dark: 'rgb(40, 43, 50)',
    light: 'rgb(255,255,255)',
    category: 'rgb(40, 43, 50)',
    subCategory: 'white',
    subCategoryItemName: 'rgb(17,18,20)',
    interestModal: 'rgb(248,249,251)',
    menu: 'rgb(26,28,32)',
    selectedMenuItem: 'rgba(0,0,0)',
    hilitedMenuItem: 'rgba(255,255,255,0.4)',
    menuDivider: 'rgb(255,255,255)',
    menuItemRibbon: 'white',
    fontLight: 'rgb(255,255,255)',
    fontDark: 'rgb(26,28,32)',
    borderLightBtn: 'rgb(218,218,218)',
    header: 'rgb(255,255,255)',
    headerDivider: 'rgb(204,204,204)',
    appContent: 'rgb(248,249,251)',
    ribbonDark: 'rgb(26,28,32)',
    insideBorder: 'rgb(222,229,232)',
    inputLine: 'rgb(214,221,228)',
    postTypeDisabled: 'rgb(239,244,250)',
    simpleInputPlaceholder: 'rgb(152,169,188)',
    postLabel: 'rgb(71,73,93)',
    error: 'rgb(245, 34, 45)'
};

export default colors;
