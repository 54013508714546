import { api, auth } from '../../services';
import MutationFactory from '../../components/hocs/MutationFactory';

export const LoginMutation = MutationFactory(api.LOGIN_MUTATION, response => {
    const {
        data: { loginAdmin: login }
    } = response;

    auth.setToken(login.UserToken.accessToken);
    auth.setRefreshToken(login.UserToken.refreshToken);
    auth.setExpiryDate(login.UserToken.expiryDate);

    return response;
});
