import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { auth } from '../../services';

class PrivateRoute extends React.Component {
    render() {
        if (!auth.getToken()) {
            return <Redirect to="/login" />;
        }
        return <Route {...this.props} />;
    }
}

export default PrivateRoute;
