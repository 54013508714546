import React from 'react';
import PropTypes from 'prop-types';

const imagePrefix = `${process.env.PUBLIC_URL}/assets/images`;

// const get2xSrc = path => {
//   const pathParts = path.split('.');
//   return `${imagePrefix}/${path.replace(`.${pathParts[pathParts.length - 1]}`, '@2x$&')}`;
// };

// const get3xSrc = path => {
//   const pathParts = path.split('.');
//   return `${imagePrefix}/${path.replace(`.${pathParts[pathParts.length - 1]}`, '@3x$&')}`;
// };

const Image = ({ className, src, alt, ...props }) => (
    <img
        className={className}
        src={`${imagePrefix}/${src}`}
        alt={alt}
        // srcSet={`${get2xSrc(src)} 2x, ${get3xSrc(src)} 3x`}
        {...props}
    />
);

Image.defaultProps = {
    className: ''
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default Image;
