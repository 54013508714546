import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spin as AntSpin, Icon } from 'antd';
import { colors } from '../../utils';

const StyledSpin = styled.div`
    width: 100%;
    .spin {
        width: 100%;
    }
    .spin-icon {
        color: ${colors.dark};
    }
`;

const Spin = ({ children, className, ...props }) => {
    const spinIcon = <Icon type="loading" spin className="spin-icon" />;
    return (
        <StyledSpin>
            <AntSpin indicator={spinIcon} wrapperClassName={`spin ${className}`} {...props}>
                {children}
            </AntSpin>
        </StyledSpin>
    );
};

Spin.defaultProps = {
    children: <></>,
    className: ''
};

Spin.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
export default Spin;
