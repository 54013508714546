import Cookies from 'js-cookie';

const getToken = () => Cookies.get('token');
const setToken = token => Cookies.set('token', token);
const setExpiryDate = expiryDate => Cookies.set('expiryDate', expiryDate);
const removeToken = () => Cookies.remove('token');
const isAuthorized = () => !!getToken();
const setRefreshToken = token => Cookies.set('refreshToken', token);
const getRefreshToken = () => Cookies.get('refreshToken');
const getExpiryDate = () => Cookies.get('expiryDate');

export default {
    getToken,
    setToken,
    removeToken,
    isAuthorized,
    setRefreshToken,
    getRefreshToken,
    setExpiryDate,
    getExpiryDate
};
