import { language } from '../../utils';

const onFormErrors = (formData = {}, formErrors = {}, errors = []) => {
    let parsedForm = {};
    Object.keys(formData).map(field => {
        const allErrorsForField = formErrors[field];
        const errorsForField =
            allErrorsForField && allErrorsForField.length
                ? allErrorsForField.filter(errorField => errors.includes(errorField))
                : [];
        parsedForm = {
            ...parsedForm,
            [field]: {
                ...formData[field],
                ...(errorsForField && errorsForField.length
                    ? {
                          errors: [
                              {
                                  message: language.languageLocalized.errorsStrings[errorsForField]
                              }
                          ]
                      }
                    : {})
            }
        };
        return null;
    });
    return parsedForm;
};

export default onFormErrors;
