/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';

import FlexContainer from '../../components/flex-container/FlexContainer';
import LoginView from './LoginView';

import { LoginMutation } from './authorization.service';
import { auth } from '../../services';

class Login extends React.Component {
    state = {
        formData: {}
    };

    componentDidMount() {
        if (auth.isAuthorized()) {
            this.props.history.push('/');
        }
    }

    handleSubmit = (mutation, values) => {
        mutation({
            variables: {
                payload: {
                    email: values.email,
                    password: values.password
                }
            }
        }).then(() => {
            this.props.history.push('/');
        });
    };

    getSubmitHandler = mutation => values => {
        this.handleSubmit(mutation, values);
    };

    render() {
        return (
            <LoginMutation>
                {(mutation, { loading, error }) => (
                    <FlexContainer>
                        <LoginView
                            onSubmit={this.getSubmitHandler(mutation)}
                            formData={this.state.formData}
                            loading={loading}
                            error={error}
                        />
                    </FlexContainer>
                )}
            </LoginMutation>
        );
    }
}

const historyShape = {
    push: PropTypes.func,
    replace: PropTypes.func
};

Login.propTypes = {
    history: PropTypes.shape(historyShape).isRequired
};
export default Login;
