import React from 'react';
import PropTypes from 'prop-types';
import { Modal as AntModal } from 'antd';
import './modal.css';

const Modal = ({ children, afterClose, titleAlignment, ...props }) => {
    let rootApp;
    const handleModalClose = () => {
        rootApp.style.removeProperty('filter');
        afterClose();
    };

    React.useEffect(() => {
        rootApp = document.body.querySelector('#root');
        if (props.visible) {
            rootApp.style.setProperty('filter', 'blur(10px)');
        }

        const modalWrapElement = rootApp.querySelector('.nwm-modal-wrapper');

        if (modalWrapElement) {
            modalWrapElement.style.setProperty('--font-color', 'red');
        }
    });
    return (
        <AntModal
            wrapClassName={`nwm-modal-wrapper title-alignment-${titleAlignment}`}
            afterClose={handleModalClose}
            destroyOnClose
            centered
            maskClosable={false}
            keyboard={false}
            {...props}
        >
            {children}
        </AntModal>
    );
};

Modal.defaultProps = {
    children: <></>,
    afterClose: () => {},
    titleAlignment: 'flex-start'
};

Modal.propTypes = {
    children: PropTypes.node,
    afterClose: PropTypes.func,
    visible: PropTypes.bool.isRequired,
    titleAlignment: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-evenly',
        'space-around'
    ])
};

export default Modal;
