/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

import LocalizedStrings from 'react-localization';
import stringsJson from './languages.json';

export const strings = new LocalizedStrings(stringsJson);

const Context = React.createContext({
    strings,
    currentLanguage: 'en',
    setLanguage: () => {}
});
export const LocalizeConsumer = Context.Consumer;

export class LocalizeProvider extends React.Component {
    state = {
        strings,
        currentLanguage: 'en',
        setLanguage: locale => {
            // eslint-disable-next-line no-param-reassign
            locale = locale || 'en';
            localStorage.setItem('language', locale);
            strings.setLanguage(locale);
            this.setState({
                currentLanguage: locale
            });
        }
    };

    componentDidMount() {
        this.state.setLanguage(localStorage.getItem('language'));
    }

    render() {
        return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>;
    }
}

LocalizeProvider.propTypes = {
    children: PropTypes.node.isRequired
};
